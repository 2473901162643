#trapezoid:hover .sub-home {
    justify-content: center;
    margin-top: 53px;
}
    #trapezoid {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        height: 0;
        border: 80px solid rgba(0,0,0,0);
        border-top: 0 solid;
        border-bottom: 110px solid rgba(111, 163, 239, .9);
        -webkit-border-radius: 20px 20px 0 0;
        border-radius: 20px 20px 0 0;
        font: normal 100%/normal Arial, Helvetica, sans-serif;
        color: rgba(0,0,0,0.7);
        -o-text-overflow: clip;
        text-overflow: clip;
        -webkit-transform: rotateX(180deg);
        transform: rotateX(180deg);
        margin-top: -53px;
        width: 75%;
        position: relative;
        transition: all 0.7s ease;
        padding-left: 2%;
        
    }
    @media (max-width: 800px) {
        #trapezoid {
            border-bottom: 90px solid rgba(111, 163, 239, .9);
        }}


    .navbar {
        position: sticky;
        top: 0;
        display: flex;
        overflow: hidden;
        justify-content: center;
        height: 80px;
        z-index: 1;
    }

        .navbar a {
            float: left;
            color: white;
            text-align: center;
            padding: 14px 25px;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 4px;
            transition: all 0.5s ease;
        }

        @media (max-width: 800px) {
            .navbar a {
                float: left;
                color: white;
                text-align: center;
                padding: 3px 8px;
                text-transform: uppercase;
                transition: all 0.5s ease;
                font-size: 11px;
                letter-spacing: 1px;
            }}

    .subnavbtn {
        font-size: 20px;
        border: none;
        outline: none;
        color: white;
        padding: 14px 25px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
        text-transform: uppercase;
        letter-spacing: 8px;
        transition: all 0.5s ease;
    }

        .navbar a:hover, .subnavbtn:hover {
            /* background: #8CA6DB; */
            background: -webkit-linear-gradient(to bottom right, #000000, #434343); 
            background: linear-gradient(to bottom right, #000000, #434343);
            color: white;
            border-radius: 5px;
            margin-top: 1.7%;
        }

    #trapezoid a {
        -webkit-transform: rotateX(180deg);
        transform: rotateX(180deg);
    }

    .pages {
        transform: rotate(180deg);
    }

    .text1{
        display:flex;
        justify-content:space-evenly;
    }          
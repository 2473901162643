.flex1{
    display:flex;
    flex-direction:row;
    align-items:center;
}

.logos{
    border-radius:50%/50%;
}

.mainBox{
    /* background-color: #8CA6DB; Blue color */
    color: white; /* Text color */
    /* border: 2px solid #8CA6DB; Darker blue border */
    background: -webkit-linear-gradient(to bottom right,  #78B3EA, #4169E1);
background: linear-gradient(to bottom right,  #78B3EA, #4169E1);
/* min-height: 1000px; */
    padding: 2%; /* Padding inside the box */
    display: flex;
    align-items: center;
    margin-left:20%;
    margin-right:20%;
    margin-top:2.5%;
    margin-bottom: 10%;
    border-radius: 15px;
}

.leftBox{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.top{
    flex:1;
    /* padding-left:15%; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bottom{
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3%;
}

.rightbox{
    flex: 1;
    padding-left: 15%;
}

.mp{
    height:50%;
    width:75%;
}

.iconRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;
    padding-left: 15%;
}

.names{
    padding-left: 25%;
    font-size: 20px;
}

.text{
    font-size: 10px;
    padding-left: 5%;
    padding-bottom: 10%;
    text-align: center;
}

.cm{
    margin-bottom: 1%;
    font-family: 'Script MT';
    font-size: 30px;
    text-align: center;
}

@media (max-width: 800px) {
.cm{
    font-size: 20px;
}
.text{
    font-size: 17px;
}
}

a { 
    color: inherit; 
} 

a:link { 
    text-decoration: none; 
  } 
  a:visited { 
    text-decoration: none; 
  } 
  a:hover { 
    text-decoration: none; 
  } 
  a:active { 
    text-decoration: none; 
  }


  @media (max-width: 800px) {
    .mainBox {
      flex-direction: column;
    }}

    @media (max-width: 800px) {
        .rightbox {
          text-align: center;
          /* padding-left: 0%;
          padding-bottom: 5%; */
          padding: 5% 0%;
        }}

/*.grad {
    height: 100%;
    background-color: red;*/ /* For browsers that do not support gradients */
    /*background-image: linear-gradient(to bottom right, red, yellow);
}*/

/*body {
    min-width: 800px;
    background: #ff00cc;*/ /* fallback for old browsers */
    /*background: -webkit-linear-gradient(to right, #333399, #ff00cc);*/ /* Chrome 10-25, Safari 5.1-6 */
    /*background: linear-gradient(to right, #333399, #ff00cc);*/ /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
/*    background: -webkit-linear-gradient(to right, green, blue);  Chrome 10-25, Safari 5.1-6 
    background: linear-gradient(to right, green, blue);  W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ 
*/

/*margin: 0px;
}*/
body {
    background: -webkit-linear-gradient(to bottom right, #000000, #434343); /* chrome 10-25, safari 5.1-6 */
    background: linear-gradient(to bottom right, #000000, #434343); /*w3c, ie 10+/ edge, firefox 16+, chrome 26+, opera 12+, safari 7+*/
}

.first{
    text-align:center;
    color:white;
}
/* 
.firstsection{
     padding-top: 5%; 
} */

html{
    scroll-behavior:smooth;
}

.mypic{
    max-width:100vh;
    width:60vh;
    height:auto;
    border-radius:90%/80%;
}
.flex-container{
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
    padding-top:.5%;
    animation: transitionIn 2s;
}
.flexcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@keyframes transitionIn{
    from{
        opacity: 0;
        transform: translateY(-25px);
    }
    to{
        opacity:1;
        transform:translateY(0)
    }
}



/*.down-arrow {
    position: absolute;
    top: calc(100vh - 60px);
    left: calc(50% - 14px);
    width: 0;
    height: 30px;
    border: 2px solid;
    border-radius: 2px;
}

    .down-arrow:after {
        content: " ";
        position: absolute;
        top: 12px;
        left: -10px;
        width: 16px;
        height: 16px;
        border-bottom: 4px solid;
        border-right: 4px solid;
        border-radius: 4px;
        transform: rotateZ(45deg);
    }
*/
#firstsection{
    height:80vh;
}

#secondsection{
    height:90vh;
    padding-top:8%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* @media (max-width: 900px) {
    #secondsection{
        height:108vh;
    }
} */

.first-col{
    flex:1 1;
    color: white;
}

#description{
    width: 80%;
}
.second-col{
    flex:1 1;
}

.color{
    background-image: -webkit-linear-gradient(to bottom right, #7299ee, #0b40de);
    background-image: linear-gradient(to bottom right, #7299ee, #0b40de);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

@media (max-width: 900px) {
    .first-col {
      flex-basis: 100%; /* Full width for small screens */
      text-align: center;
    }
    .first-col:nth-child(1) { order: 2; }
    .first-col:nth-child(2) { order: 1; }
  }
/*.arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;

}
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}*/
.arrowdown {
    box-sizing: border-box;
    height: 2vw;
    width: 2vw;
    border-style: solid;
    border-color:  rgba(111, 163, 239);
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
    transition: border-width 150ms ease-in-out;
    
}
.arrowd {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top:1%; */

}

.arrowdown:hover {
    border-bottom-width: 4px;
    border-right-width: 4px;
    cursor: pointer;
}

    /* .arrowdown:hover {
        border-bottom-width: 4px;
        border-right-width: 4px;
    } */

.arrowup {
    box-sizing: border-box;
    height: 5vw;
    width: 5vw;
    border-style: solid;
    border-color: white;
    border-width: 0px 1px 1px 0px;
    transform: rotate(225deg);
    transition: border-width 150ms ease-in-out;
/*    padding-top: 5rem;
*/}
.arrowup:hover {
    border-bottom-width: 4px;
    border-right-width: 4px;
}
.arrowu {
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom:0px;
}

.secondsection footer {
    position: absolute;
    bottom: 0;
    width: 100%;

}

p{
    font-size:23px
}

@media (max-width: 900px) {
    p{
        font-size:17px
    }
}
@media (max-width: 725px) {
    p{
        font-size:15px
    }
}

.scrolldown{
    padding-top:.5%;
    text-align:center;
    color:white;
}
.scrollup {
    text-align: center;
    color: white;
}

.name{
    font-size: 70px;
}

@media (max-width: 500px) {
    .name {
        font-size: 50px;
    }
    .abtme{
        font-size: 25px;
    }
    /* .me{
        font-size: 18px;
    } */
  }

.scrolldown{
    display: block;
    text-align: center;
    cursor: pointer;
}

@media (max-width: 900px) {
    .flex-container{
      flex-direction: column;
      justify-content: space-evenly;
      height:80vh
    }}
    @media (max-width: 900px) {
        .flexcontainer{
          flex-direction: column;
          justify-content: space-evenly;
        }}
    @media (max-width: 900px) {
        .mypic{
          width:36vh;
        }}

        @media (max-width: 900px) {
            .flex1{
              padding-top: 20%;
            }}

        /* @media (max-width: 900px) {
            #secondsection{
              min-height:150vh;
            }}

            @media (max-width: 925px) {
                #secondsection{
                  min-height:180vh;
                }} */
                /* @media (max-width: 900px) {
                    .myimages{
                        padding-bottom: 10%;
                    }
                }
                @media (max-width: 900px) {
                    .myimages{
                        padding-bottom: 0%;
                    }
                } */

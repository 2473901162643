.flex1{
    display:flex;
    justify-content: space-between;
    color:white;
    /*    padding-left:5rem;
        padding-right:5rem;*/
}

.exp {
    padding: 0rem 5rem 5rem 5rem;
    color: white;
}

.flex2 {
    display: flex;
    justify-content: space-between;
}

video{
    height:240px;
    width:360px;
}

@media only screen and (max-width:500px) {
  video {
width: 95%;
height: auto;
  }

}

.highlight{
  /* background-image: -webkit-linear-gradient(to bottom right, #000000, #434343); 
  background-image: linear-gradient(to bottom right, #000000, #434343); */
  -webkit-background-clip: text;
  background-clip: text;
  color: black;

}
img {
    height: 240px;
    width: 360px;
}
.mainbox{
    /*background-color: #8CA6DB; /* Blue color */
    /* background-color: #002349; */
    background: -webkit-linear-gradient(to bottom right, #78B3EA, #4169E1);
    background: linear-gradient(to bottom right, #78B3EA, #4169E1);
    color: white; /* Text color */
    padding: 1%; /* Padding inside the box */
    /* margin-left:20%;
    margin-right:20%;
    margin-top:5%;
    margin-bottom: 10%; */
    display: flex;
    margin: 5%;
    border-radius: 15px;
    flex: 1 1 40%;
    max-width: 40%;
    box-sizing: border-box;
    margin-bottom: 1%;
}

.mainbox_projects{
    /*background-color: #8CA6DB; /* Blue color */
    /* background-color: #002349; */
    background: -webkit-linear-gradient(to bottom right, #78B3EA, #4169E1);
    background: linear-gradient(to bottom right, #78B3EA, #4169E1);
    color: white; /* Text color */
    padding: 5%; /* Padding inside the box */
    margin-left:5%;
    margin-right:5%;
    /*margin-top:5%; */
    margin-bottom: 10%; 
    display: flex;
    border-radius: 15px;
    /* flex: 1 1 1 20%;
    max-width: 40%; */
    box-sizing: border-box;
}

.mainbox_projects_image{
  background: -webkit-linear-gradient(to bottom right, #78B3EA, #4169E1);
  background: linear-gradient(to bottom right, #78B3EA, #4169E1);
  color: white; /* Text color */
  padding: 5%; /* Padding inside the box */
  margin-left:5%;
  margin-right:5%;
  /*margin-top:5%; */
  margin-bottom: 10%; 
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  /* flex: 1 1 1 20%;
  max-width: 40%; */
  box-sizing: border-box;
}

.col{
    flex: 1;
}

.leftbox{
  flex: 0 0 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.rightBox{
    flex: 1;
    padding-left: 5%;
    display: flex;
    justify-content: center; /* Horizontal alignment */
    align-items: center;
        object-fit: cover;
    
      
}

.flexbox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.flexbox_projects{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

@keyframes fade-in{
  from{scale: .8;opacity: 0;}
  to{scale:1;opacity: 1;}
}

.pics{
    width: 100%;
    height:auto;
    scale: .8;
    opacity: 0;
    animation: fade-in linear forwards;
    animation-timeline: view();
    animation-range: 250px 450px;
}

.pi{
  width: 100%;
  height:auto;
}

@media only screen and (max-width:1050px) {
  .pics {
    animation-range: 100px 250px;
  }

}

.proj_pics{
  width: 50%;
  height:auto;

}

.img_box{
  padding-top:6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text{
    font-size: 18px;
    margin-right:5%;
    padding-bottom: 0.5%;
}

.role{
    font-size: 20px;
    padding-bottom: 5%;
}

.company{
  padding-top: 2%;
    font-size: 35px;
    padding-bottom: 2%;
    font-weight: bold;
}

.gradient-text {
    background-image: -webkit-linear-gradient(to bottom right, #ADD8E6, #4169E1);
    background-image: linear-gradient(to bottom right, #ADD8E6, #4169E1);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 100px;
    text-align: center;
    font-family: palatino, serif;
    /* padding: 12% 0% 2.5% 0%; */
    margin-bottom: 1%;
    margin-top: -3%;
    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: transitionIn 2s;
  }
  @media only screen and (max-width:1050px) {
    .gradient-text {
font-size: 60px;
height:93vh;
    }

}

  .summary{
    font-size: 25px;
    padding:2.5% 20% 0% 20%;
    font-family: sans-serif;
  }

  @media only screen and (max-width:1050px) {
    .summary {
font-size: 20px;
    }

}

  .flexy{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: -webkit-linear-gradient(to bottom right, #78B3EA, #4169E1);
    background-image: linear-gradient(to bottom right, #78B3EA, #4169E1);
    max-height: fit-content;
  }

  .flexy1{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: -webkit-linear-gradient(to bottom right, #78B3EA, #4169E1);
    background-image: linear-gradient(to bottom right, #78B3EA, #4169E1);
    max-height: fit-content;
  }

  .picy{
    flex:1;
  }

  .descy{
    flex:1;
    color: white;
    text-align: center;
  }

  /* #fromLeft{
    -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  }

  #fromRight{
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }

  #fromLeft,
  #fromRight{
    transition: opacity 250ms ease-in, -webkit-transform 400ms ease-in;
    transition: opacity 250ms ease-in, transform 400ms ease-in;
    transition: opacity 250ms ease-in, transform 400ms ease-in,
      -webkit-transform 400ms ease-in;
    opacity: 0;
  }

  #fromLeft.appear,
  #fromRight.appear{
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  } */

  .descy1{
    flex:1;
    color: white;
    text-align: center;
  }




  @media screen and (max-width: 950px) {
    .flexy {
      flex-direction: column;
    }
    .descy:nth-child(2) {
      order: -1;
    }
  }

  @media (max-width: 950px) {
    .flexy1 {
      flex-basis: 100%; 
      flex-direction: column;
    }
    .text{
      padding-bottom: 5%;
    }
  }

  /* @media (max-width: 950px) {
    .flexy {
      flex-basis: 100%; 
      flex-direction: column;
    }
  }

  @media (max-width: 950px) {
    .descy {
      flex-basis: 100%; 
      flex-direction: column;
    }
    .descy:nth-child(1) { order: 1; }
    .descy:nth-child(2) { order: 2; }
  }

  @media (max-width: 950px) {
    .flexy1 {
      flex-basis: 100%; 
      flex-direction: column;
    }
  } */

  ul{
    text-align: left;
  }

  @media (max-width: 1000px) {
    .flexbox {
      flex-direction: column;
    }}

    @media (max-width: 1000px) {
        .mainbox {
          max-width: 90%;
        }}

        .thick-line {
            height: 30px; /* Set the height of the line */
            background-color: #957C3D; /* Set the color of the line */
            margin-top: -.24%;
          }

          @media (max-width: 950px) {
            .thick-line {
              margin-top: -.625%;
            }}
  .header{
    font-size: 24px;
    padding-bottom:5%;
  }

  .arrowd1 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top:1%; */
    margin-top:8%;

}

.arrowdown1:hover {
    border-bottom-width: 4px;
    border-right-width: 4px;
    cursor: pointer;
}

.arrowdown1 {
  box-sizing: border-box;
  height: 2vw;
  width: 2vw;
  border-style: solid;
  border-color:  rgba(111, 163, 239);
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
  
}

@media (max-width: 900px) {
  .flexbox_projects {
    flex-direction: column;
  }}

  li {
    margin: 1%;
  }

  @keyframes transitionIn{
    from{
        opacity: 0;
        transform: translateY(-25px);
    }
    to{
        opacity:1;
        transform:translateY(0)
    }
}